import { PRICE_FIELD } from '../../lib/config';
import { formatPrice } from '../../lib/format';

export const toCurrency = (amount, currency, large = true) => (
  <>
    <span className={large ? 'text-' : ''}>{currency}</span>{' '}
    <span className={large ? 'text-[28px]' : ''}>{amount.toFixed(2)}</span>
  </>
);

const getParts = (pricing) => {
  let currency = 'CHF',
    price = 0,
    undiscountedPrice = 0;

  if (pricing.priceRange) {
    currency = pricing.priceRange.start.currency;
    price = pricing.priceRange.start[PRICE_FIELD]?.amount;
    undiscountedPrice =
      pricing.priceRangeUndiscounted.start[PRICE_FIELD]?.amount;
  } else if (pricing.price) {
    currency = pricing.price.currency;
    price = pricing.price[PRICE_FIELD]?.amount;
    undiscountedPrice = pricing.priceUndiscounted[PRICE_FIELD]?.amount;
  }

  return { currency, price, undiscountedPrice };
};

export const Pricing = ({
  className = '',
  pricing,
  quantity = 1,
  prefix = '',
}) => {
  const { currency, price, undiscountedPrice } = getParts(pricing);

  return (
    <div className={`whitespace-nowrap ${className}`}>
      <span className='font-bold'>
        {prefix} {toCurrency(price * quantity, currency)}
      </span>
      {!prefix && pricing.onSale && (
        <div className='relative text-lg text-black line-through'>
          {toCurrency(undiscountedPrice * quantity, currency, false)}
        </div>
      )}
    </div>
  );
};

export const SimplePricing = ({ className = '', pricing, quantity = 1 }) => {
  const { currency, price } = getParts(pricing);

  return (
    <span className={`whitespace-nowrap ${className}`}>
      {formatPrice(currency, price * quantity)}
    </span>
  );
};
